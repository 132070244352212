const firebaseConfig = {
  apiKey: 'AIzaSyAo-ii9ovQc9V4r-2TmdNxfUS1Ln5C3tDM',
  authDomain: 'northern-shield-294108.firebaseapp.com',
  databaseURL: 'https://northern-shield-294108.firebaseio.com',
  projectId: 'northern-shield-294108',
  storageBucket: 'northern-shield-294108.appspot.com',
  messagingSenderId: '209983719658',
  appId: '1:209983719658:web:00266129b9e58ce96eaf93',
  measurementId: 'G-QQ6SY3WCK6',
};

export default firebaseConfig;
